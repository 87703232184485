// Guard for the names of uploaded files.  See backend/src/models.py for
// details.
export const FILE_NAME_LIMIT = 512;

export const FormMapper = {
  theme1: "Theme One: AI for Collaborative Decision Making",
  theme2: "Theme Two: Trustworthy and Explainable AI",
  theme3: "Theme Three: AI for Design and Discovery",
  "theme1-1": "Human Understanding and Interaction",
  "theme1-2": "Uncertainty and Scalability",
  "theme1-3": "Communications",
  "theme2-1": "Safe, Fair and Robust AI System Development and Evaluation",
  "theme2-2": "Transparent or Explainable AI System Development and Evaluation",
  "theme2-3": "Explainability and Trust Assessment",
  "theme3-1": "AI Understanding of Design and Creation",
  "theme3-2": "Human AI Co-Design",
  "theme3-3": "Knowledge Representation with Complex Priors or Measurements",
  "theme3-4": "Science-Inspired or Physics-Based AI Systems and Models",
  core1: "Cognitive modelling and systems",
  core2: "Game theory and economic paradigms",
  core3: "Heuristic search and optimization",
  core4: "Knowledge representation and reasoning",
  core5: "Machine Learning",
  core6: "Multiagent Systems",
  core7: "Natural Language Processing (NLP)",
  core8: "Planning and Scheduling",
  core9: "Reasoning under uncertainty",
  core10: "Robotics",
  core11: "Search and constraint satisfaction",
  core12: "Vision",
  nus: "National University of Singapore (NUS)",
  ntu: "Nanyang Technological University (NTU)",
  smu: "Singapore Management University (SMU)",
  suss: "Singapore University of Social Science (SUSS)",
  sutd: "Singapore University of Technology and Design (SUTD)",
  sit: "Singapore Institute of Technology (SIT)",
  inter: "International",
  "a-star": "Agency for Science, Technology and Research (A*STAR)",
  PI: "Principal Investigator",
  "PI[SG]": "Principal Investigator (Singapore)",
  "PI[NZ]": "Principal Investigator (New Zealand)",
  "Co-PI": "Co Principal Investigator",
  "Co-PI[SG]": "Co Principal Investigator (Singapore)",
  "Co-PI[NZ]": "Co Principal Investigator (New Zealand)",
  collab: "Collaborator",
  "collab[SG]": "Collaborator (Singapore)",
  "collab[NZ]": "Collaborator (New Zealand)",
  eom: "Expenditure on Manpower (EOM)",
  ene: "Expenditure on New Equipment (EQP)",
  ooe: "Other Operating Expenses (OOE)",
  ot: "Overseas Travel (OT)",
  rs: "Research Scholarship (RS)",
  kpi_0: "No. of Top 10% Publications",
  kpi_1: "No. of Technologies Deployed",
  kpi_2: "No. of Manpower Spin-off to Industry",
  kpi_3:
    "Total Value-added from Projects (Projected Cost Savings/Revenue Generated)",
  "in-kind": "In Kind",
  cash: "Cash",
  direct_cost: "Direct Cost ($S)",
  indirect_cost: "Indirect Cost ($S)",
  rs_cost: "Research Scholarship ($S)",
  grand_total: "Grant Total ($S)",
  eom_cost: "Expenditure on Manpower [EOM] ($S)",
  ene_cost: "Expenditure on New Equipment [EQP] ($S)",
  ooe_cost: "Other Operating Expenses [OOE] ($S)",
  ot_cost: "Overseas Travel [OT] ($S)",
  o_eom_1: "Budget Category (Overseas Institution 1)",
  o_eom_2: "Budget Category (Overseas Institution 2)",
  sub_total: "Sub Total Cost [EOM + EQT + OOE + OT] ($S)",
};

export const SysMapper = {
  draft: "Draft",
  "complete-un": "Completed (Unendorsed)",
  "reject-cluster": "Rejected (Host Institution)",
  "complete-en": "Completed (Endorsed)",
  reject: "Rejected (AISG)",
  "revise-cluster": "Revision Request (Host Institution)",
  "revise-aisg-r": "Revision Request (AISG)",
  "revise-aisg-c": "Revision Completed (AISG)",
  proceed: "Proceed to Stage 2",
  awarded: "Awarded",
  nus: "National University of Singapore (NUS)",
  ntu: "Nanyang Technological University (NTU)",
  smu: "Singapore Management University (SMU)",
  suss: "Singapore University of Social Science (SUSS)",
  sutd: "Singapore University of Technology and Design (SUTD)",
  sit: "Singapore Institute of Technology (SIT)",
  inter: "International",
  "a-star": "Agency for Science, Technology and Research (A*STAR)",
  aisg: "AI Singapore",
  reviewer: "Reviewer",
  "admin-super": "Administrator (Superuser)",
  "admin-cluster": "Administrator (Cluster)",
  submitter: "User (Submission)",
  whitepaper: "Whitepaper",
  fullpaper: "Fullpaper",
  "whitepaper-s": "Whitepaper (Single Institution)",
  "whitepaper-m": "Whitepaper (Multiple Institutions)",
  "fullpaper-s": "Fullpaper (Single Institution)",
  "fullpaper-m": "Fullpaper (Multiple Institutions)",
  technology: "AI Technology",
  innovation: "AI Innovation",
  research: "AI Research",
};

export const RevMapper = {
  award: {
    strongly:
      "Yes. Strongly recommend for Full Proposal to be selected for award. Full Proposal is of outstanding quality and should be prioritised.",
    yes: "Yes. Recommend for Full Proposal to be selected for award.",
    no: "No. Do not recommend for Full Proposal to be selected for award.",
  },
  objectives: {
    5: "Excellent",
    4: "Good",
    3: "Average",
    2: "Poor",
    1: "Unable to Judge",
    0: "Not Applicable",
  },
  availability: {
    5: "Excellent",
    4: "Good",
    3: "Average",
    2: "Poor",
    1: "Unable to Judge",
    0: "Not Applicable",
  },
  significance: {
    5: "Excellent",
    4: "Good",
    3: "Average",
    2: "Poor",
    1: "Unable to Judge",
    0: "Not Applicable",
  },
  deployment: {
    5: "Excellent",
    4: "Good",
    3: "Average",
    2: "Poor",
    1: "Unable to Judge",
    0: "Not Applicable",
  },
  testPlans: {
    5: "Excellent",
    4: "Good",
    3: "Average",
    2: "Poor",
    1: "Unable to Judge",
    0: "Not Applicable",
  },
  team: {
    5: "Excellent",
    4: "Good",
    3: "Average",
    2: "Poor",
    1: "Unable to Judge",
    0: "Not Applicable",
  },
  outcomes: {
    5: "Excellent",
    4: "Good",
    3: "Average",
    2: "Poor",
    1: "Unable to Judge",
    0: "Not Applicable",
  },
  budget: {
    5: "Excellent",
    4: "Good",
    3: "Average",
    2: "Poor",
    1: "Unable to Judge",
    0: "Not Applicable",
  },
};

export const RevMapper_s2 = {
  award: {
    strongly:
      "Strongly recommend for Full Proposal to be selected for award. Full Proposal is of outstanding quality and should be prioritised",
    yes: "Recommend for Full Proposal to be selected for award",
    no: "Do not recommend for Full Proposal to be selected for award",
  },
  support_outcome: {
    support: "Supported",
    "not-support": "Not Supported",
  },
  scientific: {
    5: "Excellent",
    4: "Good",
    3: "Average",
    2: "Poor",
    1: "Unable to Judge",
    0: "Not Applicable",
  },
  novelty: {
    5: "Excellent",
    4: "Good",
    3: "Average",
    2: "Poor",
    1: "Unable to Judge",
    0: "Not Applicable",
  },
  feasibility: {
    5: "Excellent",
    4: "Good",
    3: "Average",
    2: "Poor",
    1: "Unable to Judge",
    0: "Not Applicable",
  },
  suitability: {
    5: "Excellent",
    4: "Good",
    3: "Average",
    2: "Poor",
    1: "Unable to Judge",
    0: "Not Applicable",
  },
  impact: {
    5: "Excellent",
    4: "Good",
    3: "Average",
    2: "Poor",
    1: "Unable to Judge",
    0: "Not Applicable",
  },
  overall_score: {
    4: "4 - Excellent: Proposal is of outstanding quality, I would like to highly recommend it to being funded.",
    3: "3 - Good: Proposal is of good quality, I think it could be funded.",
    2: "2 - Average: Proposal is lacking in some areas, but it has merits that outweigh its flaws. It can be considered after certain issues are addressed.",
    1: "1 - Poor: Proposal is lacking in one or more critical aspect, key issues are not sufficiently addressed. Its flaws outweigh its merits. Hence, it is insufficiently competitive and below the acceptance threshold.",
    0: "0 - Unable to Judge.",
  },
  manpower: {
    4: "Adequate",
    3: "Excessive",
    2: "Inadequate",
    1: "Unable to Judge",
  },
  equipment: {
    4: "Adequate",
    3: "Excessive",
    2: "Inadequate",
    1: "Unable to Judge",
  },
  other_operating: {
    4: "Adequate",
    3: "Excessive",
    2: "Inadequate",
    1: "Unable to Judge",
  },
  level_of_confidence: {
    4: "4 - Expert in the research area.",
    3: "3 - Familiar with the topic/research area.",
    2: "2 - Somewhat familiar with the topic/research area.",
    1: "1 - Not familiar with the topic/research area – can review for general AI topic of interest.",
  },
  relevance_of_proposal: {
    4: "4 – Very relevant to AI Core Technical Areas",
    3: "3 - Relevant to the AI Core Technical Areas",
    2: "2 - Somewhat relevant to the AI Core Technical Areas, but too domain specific.",
    1: "1 - Not relevant to the AI Core Technical Areas at all.",
  },
  potential_for_excellence: {
    4: "4 - Very relevant to One of the Three Themes",
    3: "3 - Relevant to One of the Three Themes",
    2: "2 - Somewhat relevant to One of the Three Themes, but too domain specific.",
    1: "1 - Not relevant to any One of the Three Themes at all.",
  },
};

/* Fullpaper */
export const fullpaper = {
  title: {
    elementType: "input-inline",
    elementConfig: {
      type: "text",
      placeholder: "enter a title for the proposal (max 200 characters)",
      isValid: false,
      isInvalid: false,
      required: true,
    },
    elementDecorators: {
      label: "[Cover Sheet] Proposal Title",
      sublabel: "",
      feedback: "valid",
      feedbackMsg: "",
    },
    validation: {
      isText: true,
      maxLength: 200,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },
  host_institution: {
    elementType: "select-inline",
    elementConfig: {
      options: [
        {
          value: "TBD",
          label: "<----- Please select from drop-down menu ----->",
        },
        {
          value: "a-star",
          label: "Agency for Science, Technology and Research (A*STAR)",
        },
        { value: "nus", label: "National University of Singapore (NUS)" },
        { value: "ntu", label: "Nanyang Technological University (NTU)" },
        { value: "smu", label: "Singapore Management University (SMU)" },
        {
          value: "sutd",
          label: "Singapore University of Technology and Design (SUTD)",
        },
        { value: "sit", label: "Singapore Institute of Technology (SIT)" },
        {
          value: "suss",
          label: "Singapore University of Social Sciences (SUSS)",
        },
      ],
      isValid: false,
      isInvalid: false,
      required: true,
    },
    elementDecorators: {
      label: "[Cover Sheet] Host Institution",
      sublabel: "",
      feedback: "valid",
      feedbackMsg: "",
      placeholders: [],
    },
    value: "TBD",
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
  },
  total_budget: {
    elementType: "input-inline",
    elementConfig: {
      type: "text",
      placeholder: "(numerical only)",
      isValid: false,
      isInvalid: false,
      required: true,
    },
    elementDecorators: {
      label: "[Cover Sheet] Total Budget Requested (S$)",
      sublabel: "Excluding Indirect Research Costs (IRC).",
      feedback: "valid",
      feedbackMsg: "",
    },
    validation: {
      isDecimal: true,
      maxLength: 10,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },
  host_institution_nz: {
    elementType: "input-inline",
    elementConfig: {
      type: "text",
      placeholder: "enter the name of the New Zealand host institution",
      isValid: false,
      isInvalid: false,
      required: true,
    },
    elementDecorators: {
      label: "[Cover Sheet] Host Institution (New Zealand)",
      sublabel: "",
      feedback: "valid",
      feedbackMsg: "",
    },
    validation: {
      isText: true,
      maxLength: 200,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },
  total_budget_nz: {
    elementType: "input-inline",
    elementConfig: {
      type: "text",
      placeholder: "(numerical only)",
      isValid: false,
      isInvalid: false,
      required: true,
    },
    elementDecorators: {
      label: "[Cover Sheet] Total Budget Requested (NZ$)",
      sublabel: "",
      feedback: "valid",
      feedbackMsg: "",
    },
    validation: {
      isDecimal: true,
      maxLength: 10,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },
  //req_budget1a: {
  //  elementType: "input-inline",
  //  elementConfig: {
  //    type: "text",
  //    placeholder: "(numerical only)",
  //    isValid: false,
  //    isInvalid: false,
  //    required: true,
  //  },
  //  elementDecorators: {
  //    label: "[Cover Sheet] Budget Requested for Stage 1a (S$)",
  //    sublabel: "Must not exceed S$3 million (including IRC).  Unused funds of Stage 1a will not roll over to Stage 1b.",
  //    feedback: "valid",
  //    feedbackMsg: "",
  //  },
  //  validation: {
  //    isDecimal: true,
  //    maxLength: 10,
  //    required: true,
  //    maxValue: 3000000,
  //  },
  //  value: "",
  //  valid: false,
  //  touched: false,
  //},
  //req_budget1b: {
  //  elementType: "input-inline",
  //  elementConfig: {
  //    type: "text",
  //    placeholder: "(numerical only)",
  //    isValid: false,
  //    isInvalid: false,
  //    required: true,
  //  },
  //  elementDecorators: {
  //    label: "[Cover Sheet] Budget Requested for Stage 1b (S$)",
  //    sublabel: "Must not exceed S$1 million (including IRC).  Unused funds of Stage 1a will not roll over to Stage 1b.",
  //    feedback: "valid",
  //    feedbackMsg: "",
  //  },
  //  validation: {
  //    isDecimal: true,
  //    maxLength: 10,
  //    required: true,
  //    maxValue: 1000000,
  //  },
  //  value: "",
  //  valid: false,
  //  touched: false,
  //},
  project_duration: {
    elementType: "static",
    elementDecorators: {
      label: "[Cover Sheet] Total Project Duration",
      sublabel: "In years. Duration fixed at 3.0 years.",
      feedback: "valid",
      feedbackMsg: "",
    },
    value: "3.0",
    valid: true,
    touched: false,
  },
  poc_nz_name: {
    elementType: "input-inline",
    elementConfig: {
      type: "text",
      placeholder:
        "enter the name of the New Zealand host institution point of contact",
      isValid: false,
      isInvalid: false,
      required: true,
    },
    elementDecorators: {
      label: "[Cover Sheet] New Zealand Host Institution Research Office",
      sublabel: "Point of Contact's Name",
      feedback: "valid",
      feedbackMsg: "",
    },
    validation: {
      isText: true,
      maxLength: 200,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },
  poc_nz_email: {
    elementType: "input-inline",
    elementConfig: {
      type: "email",
      placeholder:
        "enter the email of the New Zealand host institution point of contact",
      isValid: false,
      isInvalid: false,
      required: true,
    },
    elementDecorators: {
      label: "[Cover Sheet] New Zealand Host Institution Research Office",
      sublabel: "Point of Contact's Email Address",
      feedback: "valid",
      feedbackMsg: "",
    },
    validation: {
      isText: true,
      maxLength: 200,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },
  team_information: {
    elementType: "input-cascade",
    elementConfig: {
      data: {
        header: {
          role: {
            label: "Role",
            sublabel: "",
            config: {
              type: "select",
              options: [
                { value: "", label: "(select a role from drop-down)" },
                {
                  value: "PI[SG]",
                  label: "Principal Investigator (Singapore)",
                },
                {
                  value: "PI[NZ]",
                  label: "Principal Investigator (New Zealand)",
                },
                {
                  value: "Co-PI[SG]",
                  label: "Co-Principal Investigator (Singapore)",
                },
                {
                  value: "Co-PI[NZ]",
                  label: "Co-Principal Investigator (New Zealand)",
                },
                { value: "collab[SG]", label: "Collaborator (Singapore)" },
                { value: "collab[NZ]", label: "Collaborator (New Zealand)" },
              ],
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          firstname: {
            label: "First Name",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "Given Name",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          lastname: {
            label: "Last Name",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "Family Name",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          orcid: {
            label: "Open Researcher and Contributor ID (ORCID)",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "(max 200 characters)",
              required: false,
            },
            value: "N/A",
            valid: true,
            touched: false,
            validation: {
              maxLength: 200,
              required: false,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          institution: {
            label: "Department/ Institution",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "(max 200 characters)",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          designation: {
            label: "Designation",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "e.g Associate Professor",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          percentage_effort: {
            label: "Percentage Effort %",
            sublabel:
              "Refers to % effort spent by the researcher in the project relative to his/her other team members. The total must add up to 100%.",
            config: {
              type: "text",
              placeholder: "(max 200 characters)",
              required: true,
            },
            value: "0",
            valid: false,
            touched: false,
            validation: {
              isDecimal: true,
              maxLength: 10,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          percentage_time: {
            label: "Percentage Time %",
            sublabel:
              "Refers to % effort spent by the researcher in the project relative to his/her other job scope(s). Note that the Lead PI is expected to commit at least 25% of his/her time and the Co-PI at least 20% of his/her time to this project.",
            config: {
              type: "number",
              placeholder: "(max 10 characters)",
              required: true,
            },
            value: "0",
            valid: false,
            touched: false,
            validation: {
              isDecimal: true,
              maxLength: 10,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
        },
        content: [],
      },
      type: "stack",
      isValid: false,
      isInvalid: false,
    },
    elementDecorators: {
      label: "[Cover Sheet] Team Members",
      feedback: "valid",
      feedbackMsg: "",
      //sublabel: "There is a limit of 10 entries for this section. Please inform the admin if you need to input more entries."
    },
    value: [],
    validation: {
      required: true,
    },
    valid: true,
    touched: false,
  },
  partner_commitment: {
    elementType: "input-cascade",
    elementConfig: {
      data: {
        header: {
          //agency_name: {
          partner_name: {
            //label: "Name of Agency/Industry Partner Organisation",
            label: "Name of Industry Partner",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "Name of Partner Organisation",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          organization_type: {
            label: "Organisation Type",
            sublabel: "If choose others, please state",
            config: {
              type: "select",
              options: [
                {
                  value: "",
                  label: "<----- Please select from drop-down menu ----->",
                },
                //{ value: "gov", label: "Government" },
                {
                  value: "sg-lle",
                  label: "(Singapore) Large Local Enterprise",
                },
                {
                  value: "sg-mnc",
                  label: "(Singapore) Multi-National Corporation",
                },
                {
                  value: "sg-sme",
                  label: "(Singapore) Small & Medium Enterprise",
                },
                { value: "sg-sta", label: "(Singapore) Startup" },
                { value: "sg-oth", label: "(Singapore) Others" },
                // Purposely setting up with NZ equivalent.
                {
                  value: "nz-lle",
                  label: "(New Zealand) Large Local Enterprise",
                },
                {
                  value: "nz-mnc",
                  label: "(New Zealand) Multi-National Corporation",
                },
                {
                  value: "nz-sme",
                  label: "(New Zealand) Small & Medium Enterprise",
                },
                { value: "nz-sta", label: "(New Zealand) Startup" },
                { value: "nz-oth", label: "(New Zealand) Others" },
              ],
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          others: {
            label: "Others",
            sublabel:
              'If you select Others for "Organisation Type", please state type',
            config: {
              type: "text",
              placeholder: "Organisation Type",
              required: false,
              disabled: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          resource_type: {
            label: "Type of Resource Committed",
            sublabel: "Cash / in-kind; short descriptor",
            config: {
              type: "text",
              placeholder: "Cash / in-kind; short descriptor",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          amount_committed: {
            label: "Amount Committed",
            sublabel: "Taken as SGD for Singapore entities, and NZD for New Zealand entities",
            config: {
              type: "text",
              placeholder: "Amount Committed in relevant currency by country of organisation type",
              required: false,
            },
            value: "0",
            valid: true,
            touched: false,
            validation: {
              isDecimal: true,
              maxLength: 10,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
        },
        content: [],
      },
      type: "stack",
      isValid: false,
      isInvalid: false,
    },
    elementDecorators: {
      label: "[Cover Sheet] Commitment by Partner(s)",
      //sublabel: "There is a limit of 5 entries for this section. Please inform the admin if you need to input more entries.",
      //sublabeladd: "The total committment by partner(s) should be a minimum of 10% of Total Project Costs (includes Indirect Costs)",
      // 2023-01-25: Not needed for the Discovery AI and Robust AI GCs.
      //sublabel: "The total committment by partner(s) should be a minimum of 10% of Total Project Costs (includes Indirect Costs)",
      sublabel: "",
      feedback: "valid",
      feedbackMsg: "",
    },
    value: [],
    validation: {
      required: true,
    },
    valid: true,
    touched: false,
  },
  proposal: {
    elementType: "upload",
    label: "[SECTION I to IX] Proposal",
    sublabel: "(Please only upload .pdf format)",
    feedback: "",
    value: "",
    valid: false,
    touched: false,
    loaded: true,
    validation: {
      required: true,
    },
  },
  schedule_perf_sg: {
    elementType: "upload",
    label:
      "[SECTION X] Implementation Schedule, Performance Indicators (Singapore)",
    sublabel: "(Please only upload .pdf format)",
    feedback: "",
    value: "",
    valid: false,
    touched: false,
    loaded: true,
    validation: {
      required: true,
    },
  },
  budget: {
    elementType: "upload",
    label: "[SECTION XI] Budget Breakdown (Singapore) (max 16mb)",
    sublabel: "(Please only upload .xlsx format)",
    feedback: "",
    value: "",
    valid: true,
    touched: false,
    loaded: true,
    validation: {
      required: true,
    },
  },
  budget_nz: {
    elementType: "upload",
    label:
      "[SECTION XII] Performance Indicators & Budget Breakdown (New Zealand) (max 16mb)",
    sublabel: "(Please only upload .xlsx format)",
    feedback: "",
    value: "",
    valid: true,
    touched: false,
    loaded: true,
    validation: {
      required: true,
    },
  },
  letter_of_support: {
    elementType: "upload",
    label: "[SECTION XIII] Letter of support from industry partners (max 16mb)",
    sublabel: "(Please only upload .zip or .pdf format)",
    feedback: "",
    value: "",
    valid: true,
    touched: false,
    loaded: true,
    validation: {
      required: true,
    },
  },
  curriculum_vitae: {
    elementType: "upload",
    label:
      "[SECTION XIV] CVs of all members (All PIs, Co-PIs, and collaborators) (max 16mb)",
    sublabel: "(Please only upload .zip or .pdf format)",
    feedback: "",
    value: "",
    valid: true,
    touched: false,
    loaded: true,
    validation: {
      required: true,
    },
  },
  funding_support: {
    elementType: "upload",
    label: "[SECTION XV] Declaration Of Other Funding Support",
    sublabel: "(Please only upload .zip or .pdf format)",
    feedback: "",
    value: "",
    valid: true,
    touched: false,
    loaded: true,
    validation: {
      required: true,
    },
  },
  supplement_files: {
    elementType: "upload",
    //label: "[SECTION XV and XVI] Declaration by grant applicants and HI endorsement",
    label: "[SECTION XVI] Declaration by Grant Applicants",
    sublabel: "(Please only upload .zip or .pdf format)",
    feedback: "",
    value: "",
    valid: true,
    touched: false,
    loaded: true,
    validation: {
      required: true,
    },
  },
  supplement_endorsement_nz: {
    elementType: "upload",
    //label: "[SECTION XV and XVI] Declaration by grant applicants and HI endorsement",
    label: "[SECTION XVIII] Endorsement from Host Institution (New Zealand)",
    sublabel: "(Please only upload .zip or .pdf format)",
    feedback: "",
    value: "",
    valid: true,
    touched: false,
    loaded: true,
    validation: {
      required: true,
    },
  },
};

export const s2_endorse = {
  endorse_file: {
    elementType: "file",
    elementConfig: {
      type: "file",
      isInvalid: false,
      required: true,
    },
    elementDecorators: {
      label: "[SECTION XVII] Endorsement from Host Institution (Singapore)",
      sublabel: "(Please only upload .pdf format)",
      feedback: "valid",
      feedbackMsg: "",
    },
    validation: {
      isFile: true,
    },
    value: "",
    selectedFile: null,
    valid: false,
    touched: false,
  },
};
