import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";

// bootstrap reactjs
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

// custom components
import Input from "../../../../components/UI/Input/Input";
import MultiCheckboxes from "../../../../components/UI/Input/MultiCheckboxes/MultiCheckboxes";
import DynamicInput from "../../../../components/UI/Input/DynamicInput/DynamicInput";
import FileInput from "../../../../components/UI/Input/FileInput/FileInput";

// styling
import { customStyle } from "../../../../hoc/CustomStyle";
import { formValidity } from "../../../../hoc/Util";
import { fullpaper, FILE_NAME_LIMIT } from "../../../../hoc/FormConfig";

import cloneDeep from "lodash/cloneDeep";

class SubmissionForm extends Component {
  state = {
    render: {
      mode: "loaded", //loading
      mounted: false,
    },

    controls: null,

    timer: null,

    internalMsg: {
      triggered: false,
      type: "",
      content: "",
    },

    externalMsg: {
      triggered: false,
      type: "",
      content: "",
    },

    visibiity: {
      triggered: false,
      intervalId: "",
    },
  };

  messageHandler(msg_type, msg_content) {
    if (msg_type === "expired") {
      this.setState({
        ...this.state,
        externalMsg: {
          triggered: true,
          type: "error",
          content: msg_content,
        },
      });
    } else {
      this.setState({
        ...this.state,
        internalMsg: {
          triggered: true,
          type: msg_type,
          content: msg_content,
        },
      });
    }
  }

  componentDidMount() {
    let updatedControls = fullpaper;

    for (let elementKey in this.props.sub_infor.content) {
      if (updatedControls.hasOwnProperty(elementKey)) {
        if (
          this.props.sub_infor.content[elementKey] === "" ||
          this.props.sub_infor.content[elementKey] === []
        ) {
          updatedControls[elementKey].touched = false;
        }

        if (updatedControls[elementKey].elementType === "checkboxes-inline") {
          var splitted_items =
            this.props.sub_infor.content[elementKey].split(",");
          if (splitted_items) {
            splitted_items = splitted_items.filter(function (el) {
              return el !== "";
            });
            updatedControls[elementKey].value = splitted_items;

            if (updatedControls[elementKey].validation) {
              let validateResult = formValidity(
                updatedControls[elementKey].value,
                updatedControls[elementKey].validation
              );
              updatedControls[elementKey].valid = validateResult.isValid;

              if (updatedControls[elementKey].valid) {
                updatedControls[elementKey].isValid = true;
                updatedControls[elementKey].isInvalid = false;
                updatedControls[elementKey].elementDecorators.feedback =
                  "valid";
                updatedControls[elementKey].elementDecorators.feedbackMsg =
                  validateResult.message;
              } else {
                updatedControls[elementKey].isValid = false;
                updatedControls[elementKey].isInvalid = true;
                updatedControls[elementKey].elementDecorators.feedback =
                  "invalid";
                updatedControls[elementKey].elementDecorators.feedbackMsg =
                  validateResult.message;
              }
              updatedControls[elementKey].touched = true;
            }
          }
        } else if (
          updatedControls[elementKey].elementType === "input-cascade"
        ) {
          if (
            updatedControls[elementKey].elementConfig.type === "table-fixed"
          ) {
            updatedControls[elementKey].value = [];
            for (const key in updatedControls[elementKey].elementConfig.data
              .map) {
              let row_object = cloneDeep(
                updatedControls[elementKey].elementConfig.data.input
              );
              row_object.fixed.value = key;
              for (const subkey in row_object) {
                if (row_object[subkey].config.type !== "display") {
                  row_object[subkey].handler = (
                    event,
                    controlName,
                    colKey,
                    rowIdx
                  ) =>
                    this.dynamicInputChangedHandler(
                      event,
                      controlName,
                      colKey,
                      rowIdx
                    );
                  if (
                    updatedControls[elementKey].elementConfig.data.map[key]
                      .disabled
                  ) {
                    row_object[subkey].config.disabled = true;
                  }
                }
              }
              updatedControls[elementKey].value.push(row_object);
            }
            // inject value into the component
            let overall_valid = true;
            for (var i = 0; i < updatedControls[elementKey].value.length; i++) {
              for (let key in this.props.sub_infor.content[elementKey][i]) {
                if (key in updatedControls[elementKey].value[i])
                  updatedControls[elementKey].value[i][key].value =
                    this.props.sub_infor.content[elementKey][i][key];
                if (this.props.sub_infor.content[elementKey][i][key] !== "") {
                  //updatedControls[elementKey].value[i][key].touched = true;
                  // if (updatedControls[elementKey].validation) {
                  //   let validateResult = formValidity(
                  //     updatedControls[elementKey].value[i][key].value,
                  //     updatedControls[elementKey].value[i][key].validation
                  //   );
                  //   updatedControls[elementKey].value[i][key].valid =
                  //     validateResult.isValid;
                  //   overall_valid = overall_valid && validateResult.isValid;
                  // }
                }
              }
            }
            updatedControls[elementKey].valid = overall_valid;
          } else {
            // dynamic table handling

            let subElementKeys = [];
            for (let key in updatedControls[elementKey].elementConfig.data
              .header) {
              subElementKeys.push(key);
            }

            let rows = [];
            let length_of_content =
              this.props.sub_infor.content[elementKey].length;
            for (let idx = 0; idx < length_of_content; idx++) {
              rows.push(
                cloneDeep(updatedControls[elementKey].elementConfig.data.header)
              );
            }

            let overall_valid = true;
            for (let idx = 0; idx < rows.length; idx++) {
              for (const key of subElementKeys) {
                rows[idx][key].value =
                  this.props.sub_infor.content[elementKey][idx][key];
                rows[idx][key].handler = (event, controlName, colKey, rowIdx) =>
                  this.dynamicInputChangedHandler(
                    event,
                    controlName,
                    colKey,
                    rowIdx
                  );

                if (this.props.sub_infor.content[elementKey][idx][key] !== "") {
                  rows[idx][key].touched = true;

                  if (updatedControls[elementKey].validation) {
                    let validateResult = formValidity(
                      rows[idx][key].value,
                      rows[idx][key].validation
                    );
                    rows[idx][key].valid = validateResult.isValid;
                    overall_valid = overall_valid && validateResult.isValid;
                  }
                }
              }
              rows[idx].handler = (event, controlName, idx) =>
                this.dynamicInputDeleteHandler(event, controlName, idx);
            }

            updatedControls[elementKey].value = rows;
            updatedControls[elementKey].valid = overall_valid;
          }
        } else if (updatedControls[elementKey].elementType === "upload") {
          let validateResult = formValidity(
            this.props.sub_infor.content[elementKey],
            updatedControls[elementKey].validation
          );

          updatedControls[elementKey].value =
            this.props.sub_infor.content[elementKey];
          updatedControls[elementKey].valid = validateResult.isValid;
          updatedControls[elementKey].feedback = validateResult.message;
          updatedControls[elementKey].touched = true;
        } else if (updatedControls[elementKey].elementType === "static") {
          updatedControls[elementKey].value =
            this.props.sub_infor.content[elementKey];
        } else {
          // Covers a whole lot of evils (think "input-inline", "select-inline"
          // and a whole bunch of other stuff we haven't discovered).
          updatedControls[elementKey].value =
            this.props.sub_infor.content[elementKey];

          if (updatedControls[elementKey].value !== "") {
            let validateResult = formValidity(
              updatedControls[elementKey].value,
              updatedControls[elementKey].validation
            );
            updatedControls[elementKey].valid = validateResult.isValid;
            updatedControls[elementKey].touched = true;
          }
        }
      } else {
        // handle special keys
      }
    }

    // Bill wants 10m.  10m = 600s = 600000ms.
    var intervalId = setInterval(() => {
      this.submitHandler("save");
    }, 600000);

    this.setState({
      ...this.state,
      render: {
        ...this.state.render,
        mounted: true,
      },
      controls: updatedControls,

      visibiity: {
        ...this.state.visibiity,
        intervalId: intervalId,
      },
    });
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
      clearInterval(this.state.visibiity.intervalId);
    }

    this.setState({
      ...this.state,
      render: {
        mode: "loaded", //loading
        mounted: false,
      },
      controls: null,
    });
  }

  uploadInputHandler = (event, controlName) => {
    let updatedStates = { ...this.state.controls };

    if (event.target.files[0]) {
      let success = true;
      let message = "";

      if (success && event.target.files[0].size > 32000000) {
        success = false;
        message = "file size more than 32mb!";
      }

      // Aftermath of Research GMS #137.
      if (success && event.target.files[0].name.length >= FILE_NAME_LIMIT) {
        success = false;
        message = "file name longer than " + FILE_NAME_LIMIT + " characters!";
      }

      if (success) {
        updatedStates[controlName].loaded = false;
        updatedStates[controlName].touched = true;

        let uploadFormData = new FormData();
        let url_link = "proposal";
        uploadFormData.set("upload_type", controlName);

        uploadFormData.set(
          "submission_id",
          this.props.sub_infor.content.submission_id
        );
        uploadFormData.append("upload", event.target.files[0]);

        let accessToken = localStorage.getItem("access_token");
        if (accessToken) {
          axios({
            url: process.env.REACT_APP_AXIOS_URL + "upload/" + url_link,
            method: "post",
            auth: {
              username: accessToken,
              password: "unused",
            },
            data: uploadFormData,
          })
            .then((received) => {
              if (received.status === 200) {
                if (received.data.status) {
                  updatedStates[controlName].loaded = true;
                  updatedStates[controlName].value = received.data.filename;
                  updatedStates[controlName].valid = received.data.status;
                  updatedStates[controlName].feedback = "upload success!";
                  this.setState({ controls: updatedStates });
                } else {
                  updatedStates[controlName].loaded = true;
                  updatedStates[controlName].value = "";
                  updatedStates[controlName].valid = received.data.status;
                  updatedStates[controlName].feedback = received.data.message;
                  this.setState({ controls: updatedStates });
                }
              } else {
                updatedStates[controlName].value = "";
                updatedStates[controlName].valid = received.data.status;
                updatedStates[controlName].feedback = "response error not 200";
                this.setState({ controls: updatedStates });
              }
            })
            .catch((error) => {
              if (error.response && error.response.status === 401) {
                this.messageHandler(
                  "expired",
                  "Session expired. Please log in again"
                );
              } else {
                updatedStates[controlName].value = "";
                updatedStates[controlName].valid = false;
                updatedStates[controlName].feedback =
                  "response error: " + error;
                this.setState({ controls: updatedStates });
              }
            });
        }
      } else {
        updatedStates[controlName].valid = false;
        updatedStates[controlName].touched = true;
        updatedStates[controlName].feedback = message;
      }

      this.setState({ controls: updatedStates });
    }
  };

  downloadHandler(submission_id, controlName) {
    // We could use the ES6 default value, but this method restores old
    // behaviours for download links that we didn't update.
    let url_link = controlName || "proposal";
    if (this.props.sub_infor.content["status"] !== "draft") {
      url_link = "fullpaper";
    }

    if (url_link === controlName) {
      // Prepend an identifier specific for the back-end.
      url_link = "submission_component-" + controlName;
    }
    const FileDownload = require("js-file-download");

    let accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      axios({
        url: process.env.REACT_APP_AXIOS_URL + "download/file",
        method: "post",
        auth: {
          username: accessToken,
          password: "unused",
        },
        data: {
          submission_id: submission_id,
          download_type: url_link,
        },
        responseType: "arraybuffer",
      })
        .then((received) => {
          //FileDownload(received.data, this.props.sub_infor.content["title"] + ".zip");
          FileDownload(received.data, received.headers["content-type"]);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.messageHandler(
              "expired",
              "Session expired. Please log in again"
            );
          } else {
            this.messageHandler(
              "error",
              "An Unexpected error has occurred, please contact site admin if persists."
            );
          }
        });
    }
  }

  multicheckHandler(event, controlName) {
    let updatedControlState = this.state.controls[controlName];

    if (event.target.checked) {
      updatedControlState.value.push(event.target.value);
    } else {
      var copy = [...updatedControlState.value];
      var index = copy.indexOf(event.target.value);
      if (index !== -1) {
        copy.splice(index, 1);
        updatedControlState.value = copy;
      }
    }

    if (
      updatedControlState.value.length >
      updatedControlState.validation.maxChoices
    ) {
      updatedControlState.valid = false;
      updatedControlState.touched = true;
      updatedControlState.elementConfig.isValid = false;
      updatedControlState.elementConfig.isInvalid = true;
      updatedControlState.elementDecorators.feedback = "invalid";
      updatedControlState.elementDecorators.feedbackMsg =
        "Please only up to 3 choices";
    } else {
      updatedControlState.valid = true;
      updatedControlState.touched = true;
      updatedControlState.elementConfig.isValid = true;
      updatedControlState.elementConfig.isInvalid = false;
      updatedControlState.elementDecorators.feedback = "valid";
      updatedControlState.elementDecorators.feedbackMsg = "looks good!";
    }

    const updatedControls = {
      ...this.state.controls,
      [controlName]: updatedControlState,
    };

    this.setState({ controls: updatedControls });
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  inputChangedHandler = (event, controlName) => {
    let validateOutput = null;
    if (controlName === "a_variable") {
      validateOutput = formValidity(
        event.target.value,
        this.state.controls.s2_budget_eom_overseas_1.infusion.validation
      );
    } else if (controlName === "b_variable") {
      validateOutput = formValidity(
        event.target.value,
        this.state.controls.s2_budget_eom_overseas_2.infusion.validation
      );
    } else {
      validateOutput = formValidity(
        event.target.value,
        this.state.controls[controlName].validation
      );
    }

    let updatedControls = { ...this.state.controls };

    if (controlName === "a_variable") {
      updatedControls.s2_budget_eom_overseas_1.infusion.value =
        event.target.value;
      updatedControls.s2_budget_eom_overseas_1.infusion.valid =
        validateOutput.isValid;
      updatedControls.s2_budget_eom_overseas_1.infusion.feedback.type =
        validateOutput.isValid ? "valid" : "invalid";
      updatedControls.s2_budget_eom_overseas_1.infusion.feedback.message =
        validateOutput.message;
      updatedControls.s2_budget_eom_overseas_1.infusion.touched = true;
    } else if (controlName === "b_variable") {
      updatedControls.s2_budget_eom_overseas_2.infusion.value =
        event.target.value;
      updatedControls.s2_budget_eom_overseas_2.infusion.valid =
        validateOutput.isValid;
      updatedControls.s2_budget_eom_overseas_2.infusion.feedback.type =
        validateOutput.isValid ? "valid" : "invalid";
      updatedControls.s2_budget_eom_overseas_2.infusion.feedback.message =
        validateOutput.message;
      updatedControls.s2_budget_eom_overseas_2.infusion.touched = true;
    } else {
      updatedControls = {
        ...this.state.controls,
        [controlName]: {
          ...this.state.controls[controlName],
          value: event.target.value,
          valid: validateOutput.isValid,
          elementDecorators: {
            ...this.state.controls[controlName].elementDecorators,
            feedbackMsg: validateOutput.message,
          },
          touched: true,
        },
      };
    }

    if (controlName === "theme") {
      updatedControls.theme_topics.value = [];
    }

    this.setState({ controls: updatedControls });
  };

  dynamicInputAddHandler = (event, controlName) => {
    let subElementKeys = [];
    for (let key in this.state.controls[controlName].elementConfig.data
      .header) {
      subElementKeys.push(key);
    }

    let validated = true;
    var col = cloneDeep(
      this.state.controls[controlName].elementConfig.data.header
    );
    let rows = [...this.state.controls[controlName].value, col];

    // We see if there's a limit for this control: if there is, we do NOT
    // add a new row (and yell at the user instead).
    let maxEntries = this.state.controls[controlName].elementConfig.maxEntries;
    console.log(maxEntries);

    if (maxEntries !== undefined) {
      let entryLength = this.state.controls[controlName].value.length;
      let elt = this.state.controls[controlName];
      console.log(elt);
      if (entryLength === maxEntries) {
        window.alert(
          'Warning, "' +
            elt.elementDecorators.label +
            '" is limited to ' +
            maxEntries +
            " row" +
            (maxEntries === 1 ? "" : "s") +
            ", so you cannot add more."
        );
        return;
      } else if (entryLength >= maxEntries) {
        // This is legacy data, so we warn the user.
        let diff = entryLength - maxEntries;
        window.alert(
          'Warning, "' +
            elt.elementDecorators.label +
            '" is limited to ' +
            maxEntries +
            " row, but there are now " +
            entryLength +
            ".\n\nYou must remove " +
            diff +
            " row" +
            (diff === 1 ? "" : "s") +
            "."
        );
        return;
      }
      // Pass through -- do not try to refactor the returns.
    }

    for (let idx = 0; idx < rows.length; idx++) {
      for (const key of subElementKeys) {
        rows[idx][key].handler = (event, controlName, colKey, rowIdx) =>
          this.dynamicInputChangedHandler(event, controlName, colKey, rowIdx);
        validated = rows[idx][key].valid && validated;
      }

      rows[idx].handler = (event, controlName, idx) =>
        this.dynamicInputDeleteHandler(event, controlName, idx);
    }

    const updatedRowElements = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: rows,
        valid: validated,
      },
    };

    this.setState({ controls: updatedRowElements });
  };

  dynamicInputDeleteHandler = (event, controlName, rowIdx) => {
    var updatedValue = [...this.state.controls[controlName].value];
    // let updatedSubStates = this.state.controls["budget_summary"].value;
    let validated = true;
    if (rowIdx !== -1) {
      updatedValue.splice(rowIdx, 1);

      for (let idx = 0; idx < updatedValue.length; idx++) {
        for (let key in updatedValue[idx]) {
          if (key !== "handler")
            validated = updatedValue[idx][key].valid && validated;
        }
      }
    }

    /*     if (controlName.includes("s1_") ||
          controlName.includes("s2_")) {
          var total = 0;
          for (var idx = 0; idx < updatedValue.length; idx++) {
            total += Number(updatedValue[idx]["total"].value)
          };
    
          let summaryField = "";
          if (controlName.includes("s1_"))
            summaryField = "s1_budget"
          else
            summaryField = "s2_budget"
    
          var position = 0;
          switch (controlName.substr(10)) {
            case "eom":
              position = 0;
              break;
            case "ene":
              position = 1;
              break;
            case "ooe":
              position = 2;
              break;
            case "ot":
              position = 3;
              break;
            default:
              break;
          }
    
          updatedSubStates[position][summaryField].value = total;
    
          total = 0;
          for (var idx = 0; idx < 4; idx++)
            total += Number(updatedSubStates[idx][summaryField].value);
          updatedSubStates[4][summaryField].value = total;
        } */

    this.setState({
      controls: {
        ...this.state.controls,
        [controlName]: {
          ...this.state.controls[controlName],
          value: updatedValue,
          valid: validated,
        },
        // ["budget_summary"]: {
        //   ...this.state.controls["budget_summary"],
        //   value: updatedSubStates,
        //   valid: validated,
      },
      // },
    });
  };

  dynamicInputChangedHandler = (event, controlName, colKey, rowIdx) => {
    let validateOutput = formValidity(
      event.target.value,
      this.state.controls[controlName].value[rowIdx][colKey].validation
    );

    let updatedSubStates = this.state.controls[controlName].value;
    //let budget_summary = this.state.controls["budget_summary"].value;

    updatedSubStates[rowIdx][colKey] = {
      ...updatedSubStates[rowIdx][colKey],
      value: event.target.value,
      valid: validateOutput.isValid,
      feedback: {
        type: validateOutput.isValid ? "valid" : "invalid",
        message: validateOutput.message,
      },
      touched: true,
    };

    let validate = true;
    for (var idx = 0; idx < updatedSubStates.length; idx++) {
      for (let key in updatedSubStates[idx]) {
        if (key !== "handler") {
          validate = updatedSubStates[idx][key].valid && validate;
        }
      }
    }

    if (controlName === "partner_commitment") {
      Object.entries(updatedSubStates).forEach(([formKey, formEntry], idx) => {
        if (formEntry.organization_type.value.endsWith("oth")) {
          formEntry.others.config.required = true;
          formEntry.others.config.disabled = false;
        } else {
          formEntry.others.config.required = false;
          formEntry.others.config.disabled = true;
        }
      });
      validate = true;
    }

    // control the number of Pi and Co-Pi assignments.  Is very different -- see
    // the criteria in #48.
    // - #(PI[SG]) == 1
    // - #(PI[NZ]) == 1
    // - #(Co-PI[SG]) >= 1
    if (controlName === "team_information") {
      let counters = {
        "PI[SG]": 0,
        "PI[NZ]": 0,
        "Co-PI[SG]": 0,
        "Co-PI[NZ]": 0,
        "collab[SG]": 0,
        "collab[NZ]": 0,
      };
      let has_error = false;
      console.log(counters);

      for (var idx = 0; idx < updatedSubStates.length; idx++) {
        counters[updatedSubStates[idx]["role"].value] += 1;
        console.log(counters);

        if (counters["PI[SG]"] > 1) {
          updatedSubStates[idx]["role"].valid = false;
          updatedSubStates[idx]["role"].feedback.type = "invalid";
          updatedSubStates[idx]["role"].feedback.message =
            "Number of Principal Investigator from Singapore cannot exceed 1!";
          has_error = true;
          break;
        } else if (counters["PI[NZ]"] > 1) {
          updatedSubStates[idx]["role"].valid = false;
          updatedSubStates[idx]["role"].feedback.type = "invalid";
          updatedSubStates[idx]["role"].feedback.message =
            "Number of Principal Investigator from New Zealand cannot exceed 1!";
          has_error = true;
          break;
        }
      }
      if (!has_error) {
        for (var idx = 0; idx < updatedSubStates.length; idx++) {
          let validate = formValidity(
            updatedSubStates[idx]["role"].value,
            updatedSubStates[idx]["role"].validation
          );
          updatedSubStates[idx]["role"].valid = validate.isValid;
          updatedSubStates[idx]["role"].feedback.type = validateOutput.isValid
            ? "valid"
            : "invalid";
          updatedSubStates[idx]["role"].feedback.message =
            validateOutput.message;
        }
      }
    }

    var updatedControls = null;

    updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: updatedSubStates,
        valid: validate,
        elementDecorators: {
          ...this.state.controls[controlName].elementDecorators,
          feedbackMsg: validateOutput.message,
        },
        touched: true,
      },
    };

    this.setState({
      ...this.state,
      controls: updatedControls,
    });
  };

  submitHandler = (type) => {
    //event.preventDefault();

    let values = {};
    if (this.props.sub_infor.content.submission_id) {
      values["submission_id"] = this.props.sub_infor.content.submission_id;
      values["grant_id"] = this.props.sub_infor.content.grant_id;
    }

    let validated = true;
    let debug_message = "";
    let detailed_message = "";
    Object.entries(this.state.controls).forEach(([formKey, formEntry], idx) => {
      if (validated && !formEntry.valid) {
        validated = false;
        debug_message = formKey;
      }

      // load data
      if (formEntry.elementType === "input-cascade") {
        let formValue = [];
        for (var i = 0; i < formEntry.value.length; i++) {
          let rowData = {};
          for (let key in formEntry.value[i]) {
            if (key !== "handler") {
              rowData[key] = formEntry.value[i][key].value;
            }
          }
          formValue.push(rowData);
        }
        values[formKey] = formValue;

        // Do the Team Information validation.  See line 854 for details.
        if (formKey === "team_information") {
          let counters = {
            "PI[SG]": 0,
            "PI[NZ]": 0,
            "Co-PI[SG]": 0,
            "Co-PI[NZ]": 0,
            "collab[SG]": 0,
            "collab[NZ]": 0,
          };
          let min_commitments = {
            PI: -1,
            "Co-PI": -1,
          };
          let sum_effort = 0;
          for (var i = 0; i < formValue.length; i++) {
            counters[formValue[i]["role"]] += 1;

            sum_effort += parseFloat(formValue[i]["percentage_effort"]);

            // Just unroll the check -- it's lazier.
            if (formValue[i]["role"].startsWith("PI")) {
              if (min_commitments["PI"] < 0) {
                min_commitments["PI"] = parseFloat(
                  formValue[i]["percentage_time"]
                );
              } else {
                min_commitments["PI"] = Math.min(
                  min_commitments["PI"],
                  parseFloat(formValue[i]["percentage_time"])
                );
              }
            }
            if (formValue[i]["role"].startsWith("Co-PI")) {
              if (min_commitments["Co-PI"] < 0) {
                min_commitments["Co-PI"] = parseFloat(
                  formValue[i]["percentage_time"]
                );
              } else {
                min_commitments["Co-PI"] = Math.min(
                  min_commitments["Co-PI"],
                  parseFloat(formValue[i]["percentage_time"])
                );
              }
            }
          }

          if (counters["PI[SG]"] !== 1) {
            validated = false;
            debug_message = formKey;
            detailed_message =
              "Exactly one Principal Investigator from Singapore required.";
          } else if (counters["PI[NZ]"] !== 1) {
            validated = false;
            debug_message = formKey;
            detailed_message =
              "Exactly one Principal Investigator from New Zealand required.";
          } else if (counters["Co-PI[SG]"] < 1) {
            validated = false;
            debug_message = formKey;
            detailed_message =
              "At least one Co-Principal Investigator from Singapore required.";
          }

          // Do the sums.
          else if (min_commitments["PI"] < 25) {
            validated = false;
            debug_message = formKey;
            detailed_message =
              "At least one Principal Investigator has committed less than 25% of their time (lowest was " +
              min_commitments["PI"] +
              "%).";
          } else if (min_commitments["Co-PI"] < 20) {
            validated = false;
            debug_message = formKey;
            detailed_message =
              "At least one Co-Principal Investigator has committed less than 20% of their time (lowest was " +
              min_commitments["Co-PI"] +
              "%).";
          } else if (Math.abs(sum_effort - 100) > 1e-5) {
            validated = false;
            debug_message = formKey;
            detailed_message =
              "Total percentage effort isn't at 100% (currently " +
              sum_effort +
              "%).";
          }
        }
      } else if (formEntry.elementType === "checkboxes-inline") {
        values[formKey] = formEntry.value.join(",");
      } else {
        values[formKey] = formEntry.value;
      }
    });

    if (type === "save") {
      validated = true;
    } else {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
        clearInterval(this.state.visibiity.intervalId);
      }
    }

    if (validated) {
      let accessToken = localStorage.getItem("access_token");
      if (accessToken) {
        axios({
          url: process.env.REACT_APP_AXIOS_URL + "proposals/post",
          method: "post",
          auth: {
            username: accessToken,
            password: "unused",
          },
          data: {
            post_type: "update",
            post_data: values,
            mode: type,
          },
        })
          .then((received) => {
            if (received.status === 200) {
              if (received.data.status) {
                if (type !== "save") {
                  this.props.formHandler();
                } else {
                  this.setState({
                    ...this.state,
                    render: {
                      ...this.state.render,
                      mode: "loaded",
                    },
                  });
                }
              } else {
                this.setState({
                  ...this.state,
                  render: {
                    ...this.state.render,
                    mode: "loaded",
                  },
                  internalMsg: {
                    triggered: true,
                    type: "error",
                    content: received.data.message,
                  },
                });
              }
            } else {
              this.setState({
                ...this.state,
                render: {
                  ...this.state.render,
                  mode: "loaded",
                },
                internalMsg: {
                  triggered: true,
                  type: "error",
                  content: "received state not 200",
                },
              });
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              this.messageHandler(
                "expired",
                "Session expired. Please log in again"
              );
            } else {
              this.setState({
                ...this.state,
                render: {
                  ...this.state.render,
                  mode: "loaded",
                },
                internalMsg: {
                  triggered: true,
                  type: "error",
                  content: "critical error: " + error,
                },
              });
            }
          });
      }

      this.setState({
        ...this.state,
        render: {
          ...this.state.render,
          mode: "loading",
        },
      });
    } else {
      let field = "";
      if (this.state.controls[debug_message].elementDecorators) {
        field = this.state.controls[debug_message].elementDecorators.label;
      } else {
        field = this.state.controls[debug_message].label;
      }
      this.setState({
        ...this.state,
        internalMsg: {
          triggered: true,
          type: "error",
          content:
            "Something is wrong with '" +
            field +
            "' field" +
            (detailed_message.length > 0 ? ": " + detailed_message : ""),
        },
      });
    }
  };

  submitWrapper(event, confirmMsg) {
    event.preventDefault();
    if (window.confirm(confirmMsg) === true) {
      clearInterval(this.state.visibiity.intervalId);
      this.submitHandler("submit");
    }
  }

  render() {
    let displayBudget = null;

    // message handling
    let msg = null;
    if (this.state.internalMsg.triggered) {
      if (this.state.internalMsg.type === "success") {
        msg = (
          <p style={customStyle.successMessage}>
            {this.state.internalMsg.content}
          </p>
        );
      } else if (this.state.internalMsg.type === "error") {
        msg = (
          <p style={customStyle.errorMessage}>
            {this.state.internalMsg.content}
          </p>
        );
      }
    }

    // redirect to login page if there is any error
    if (
      this.state.externalMsg.triggered &&
      this.state.externalMsg.type === "error"
    ) {
      localStorage.clear("access_token");
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              message: {
                type: "error",
                content: this.state.externalMsg.content,
              },
            },
          }}
        />
      );
    }

    const formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key],
      });
    }

    const form = formElementsArray.map((formElement) => {
      if (formElement.config.elementType === "input-cascade") {
        if (formElement.config.elementConfig.type === "table-fixed") {
          return (
            <DynamicInput
              key={formElement.id}
              element={formElement.config}
              controlName={formElement.id}
            />
          );
        } else {
          return (
            <>
              {formElement.id === "s2_budget_eom" ? displayBudget : null}
              <DynamicInput
                key={formElement.id}
                element={formElement.config}
                controlName={formElement.id}
                onadd={(event) =>
                  this.dynamicInputAddHandler(event, formElement.id)
                }
              />
            </>
          );
        }
      } else if (formElement.config.elementType === "checkboxes-inline") {
        if (formElement.id === "theme_topics") {
          return (
            <MultiCheckboxes
              key={formElement.id}
              element={formElement.config}
              dependent={this.state.controls.theme.value}
              onchanged={(event) =>
                this.multicheckHandler(event, formElement.id)
              }
            />
          );
        } else {
          return (
            <MultiCheckboxes
              key={formElement.id}
              element={formElement.config}
              dependent={null}
              onchanged={(event) =>
                this.multicheckHandler(event, formElement.id)
              }
            />
          );
        }
      } else if (formElement.config.elementType === "upload") {
        if (this.props.grant_infor.user_type === "admin-cluster") return null;
        else {
          // FIXME Might want to add checks relating to submission state
          // before showing either the file input component, or just a
          // download button.  But to check submission state, backend needs
          // to update the DATA_CONFIG["form"] to pass a hidden value for
          // "status".
          return (
            <FileInput
              key={formElement.id}
              label={formElement.config.label}
              sublabel={formElement.config.sublabel}
              value={formElement.config.value}
              feedback={formElement.config.feedback}
              valid={formElement.config.valid}
              touched={formElement.config.touched}
              loaded={formElement.config.loaded}
              uploadhandler={(event) =>
                this.uploadInputHandler(event, formElement.id)
              }
              downloadhandler={() =>
                // Updated to allow individual file downloads where applicable.
                this.downloadHandler(
                  this.props.sub_infor.content.submission_id,
                  formElement.id
                )
              }
            />
          );
        }
      } else {
        return (
          <Input
            key={formElement.id}
            label={formElement.config.label}
            value={formElement.config.value}
            elementType={formElement.config.elementType}
            elementConfig={formElement.config.elementConfig}
            elementDecorators={formElement.config.elementDecorators}
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validation}
            touched={formElement.config.touched}
            changed={(event) => this.inputChangedHandler(event, formElement.id)}
          />
        );
      }
    });

    let submit_btn = null;
    let download_btn = null;
    let save_btn = (
      <Button variant="primary" onClick={() => this.submitHandler("save")}>
        Save
      </Button>
    );
    if (
      this.props.sub_infor.content["status"] === "draft" ||
      this.props.sub_infor.content["status"] === "revise-cluster"
    ) {
      submit_btn = (
        <Button variant="success" type="submit">
          Submit
        </Button>
      );

      download_btn = (
        <Button
          variant="warning"
          onClick={() =>
            this.downloadHandler(this.props.sub_infor.content.submission_id)
          }
        >
          Download Draft
        </Button>
      );
    } else {
      submit_btn = (
        <Button
          variant="warning"
          onClick={(event) =>
            this.downloadHandler(this.props.sub_infor.content.submission_id)
          }
        >
          Download full application
        </Button>
      );
    }
    let submit_msg = null;

    if (this.state.render.mode === "loading") {
      save_btn = (
        <Button variant="primary" disabled>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Saving ...
        </Button>
      );

      submit_btn = (
        <Button variant="success" disabled>
          Submit
        </Button>
      );
      submit_msg = (
        <>
          <p style={customStyle.subConfirm}>
            <Spinner animation="border" size="sm" />
            Submitting... You will be automatically redirected upon successful
            submission, please do not refresh the page or hit the back button.
          </p>
        </>
      );
    }

    let confirmMsg =
      "Confirm submission? You will not be able to modify your application after submission.";
    let submitEvent = (event) => this.submitWrapper(event, confirmMsg);

    if (
      this.props.grant_infor.user_type === "admin-super" ||
      this.props.grant_infor.user_type === "admin-cluster"
    ) {
      submitEvent = (event) => this.submitWrapper(event, confirmMsg);
    }

    return (
      <>
        <p style={customStyle.subForeWord}>
          ** Please save your submission regularly by using the Save button
        </p>
        <Button
          variant="info"
          onClick={() => {
            this.props.formHandler("back");
            clearInterval(this.state.visibiity.intervalId);
          }}
        >
          Back
        </Button>{" "}
        {save_btn}
        <div style={customStyle.topBuffer20}>
          <Form id="" onSubmit={submitEvent}>
            {this.state.render.mounted ? form : <p>Loading ...</p>}
            {this.props.grant_infor.user_type === "admin-cluster" ? (
              <Button
                variant="info"
                onClick={() => {
                  this.props.formHandler("back");
                  clearInterval(this.state.visibiity.intervalId);
                }}
              >
                Back
              </Button>
            ) : (
              submit_btn
            )}{" "}
            {save_btn} {download_btn}
          </Form>
          {msg}
          <br></br>
          <p style={customStyle.subText}>
            Note: Your submission will not be finalized until you click on the
            submit button!
          </p>
          {submit_msg}
        </div>
      </>
    );
  }
}

export default SubmissionForm;
